import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { ZodDeskInfo } from "gather-common/dist/src/public/resources/desks"
import { SpaceUserResource } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const users = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceUsers,
    responses: { 200: c.type<SpaceUserResource[]>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceUsers,
    body: z.object({
      inviteToken: z.string(),
    }),
    responses: { 200: c.noBody() },
  },

  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceUser,
    responses: { 200: c.type<SpaceUserResource>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceUser,
    body: z.object({
      name: z.string().optional(),
      deskInfo: ZodDeskInfo.optional(),
    }),
    responses: { 200: c.type<SpaceUserResource>() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.SpaceUser,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
})
