import { z } from "zod"

// Verify that a user works for gather via their email address
export function validateGatherEmail(email: string | null): boolean {
  const emailSchema = z
    .string()
    .email()
    .refine((e) => e.endsWith("@gather.town"))
  return emailSchema.safeParse(email).success
}
