export {
  getBaseSpaceLink,
  getDashboardPath,
  getDashboardPathOrThrow,
  getHumanReadableSpaceIdParam,
  getHumanReadableSpacePath,
  getSpacePathFromId,
  getSpacePathFromIdOrThrow,
  getSpaceStudioPathFromId,
  getURLBaseDomain,
  getUrlFromSpaceId,
  isLocalGatherOrigin,
  isURLFromValidGatherOrigin,
  PATH_PATTERN_APP_HOME,
  PATH_PATTERN_APP_SPACE,
  PATH_PATTERN_DASHBOARD,
  PATH_PATTERN_STUDIO,
  PATH_PREFIX_APP,
  PATH_PREFIX_DASHBOARD,
  PATH_PREFIX_STUDIO,
} from "../url"
