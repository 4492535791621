import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { GoogleCalendarSelectors } from "gather-common/dist/src/public/browserExtension"
import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

export const browserExtension = c.router({
  config: {
    get: {
      method: RequestMethod.POST,
      path: HttpV2Paths.BrowserExtensionConfig,
      body: z.object({
        hash: z.string().nullable(),
      }),
      responses: {
        200: c.type<
          | { cached: true }
          | {
              cached: false
              config: {
                GOOGLE_CALENDAR_SELECTORS: GoogleCalendarSelectors
              }
              hash: string
            }
        >(),
      },
    },
  },
})
