import { initContract } from "@ts-rest/core"
import { z } from "zod"

import {
  CF_IP_LAT_HEADER,
  CF_IP_LONG_HEADER,
} from "gather-common-including-video/dist/src/public/ip"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { zodUuid } from "../../zodHelpers"

const c = initContract()

export const MAX_SEARCH_LIMIT = 500

export const logicServerAssignment = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceLogicServerAssignment,
    pathParams: z.object({ spaceId: zodUuid }),
    headers: z.object({
      [CF_IP_LAT_HEADER]: z.string().optional(),
      [CF_IP_LONG_HEADER]: z.string().optional(),
    }),
    responses: {
      200: c.type<{
        url: string | null
      }>(),
    },
  },
})
