import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { UserAccountResource } from "gather-common/dist/src/public/resources/user"
import { UserAccountV2Prisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export type AdminDashboardUserResource = {
  firebaseAuthId: UserAccountV2Prisma["firebaseAuthId"]
  email: UserAccountV2Prisma["email"]
}

export const users = c.router({
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.AdminAuthUsers,
    body: z.object({
      emails: z.array(z.string().email()),
    }),
    responses: { 200: c.type<{ emails: string[] }>() },
  },
  getByEmailOrId: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminAuthUser,
    responses: {
      200: c.type<UserAccountResource>(),
    },
  },
})
