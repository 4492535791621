import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { MAX_NAME_LENGTH } from "gather-common/dist/src/public/constants"
import { UserAccountV2Prisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export enum UserDataFields {
  selectedLanguage = "selectedLanguage",
}

type UserDataFieldResource = string | boolean | undefined | null

export const data = c.router({
  field: {
    get: {
      method: RequestMethod.GET,
      path: HttpV2Paths.UserDataField,
      pathParams: z.object({
        userId: z.string(),
        field: z.nativeEnum(UserDataFields),
      }),
      responses: { 200: c.type<UserDataFieldResource>(), 204: c.noBody() },
    },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.UserData,
    body: z
      .object({
        name: z.string().max(MAX_NAME_LENGTH),
        selectedLanguage: z.string(),
      })
      .partial(),
    responses: { 200: c.type<UserAccountV2Prisma>(), 204: c.noBody() },
  },
})
