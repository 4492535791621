import { initContract } from "@ts-rest/core"

import { SpaceResource } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

export const spaces = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.Space,
    responses: { 200: c.type<SpaceResource>() },
  },
})
