import "./ClientStateTool.css"

import { TabContext, TabList } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import { FC, useEffect, useMemo } from "react"
import {
  useAVClientStateViewerDispatchers,
  useCurrentSession,
} from "state/avClientStateViewerSlice"

import { BrowserVideoClientStateSession } from "gather-prisma-types/dist/src/public/client"
import Dashboard from "./Dashboard"
import DevicesTab from "./DevicesTab"
import Events from "./Events"
import CSTLayout from "./Layout"
import LogEvents from "./LogEvents"
import Participants from "./Participants"
import {
  actionRenderer,
  combinedRenderers,
  networkTestResultsRenderer,
  rawRenderer,
  sfuRenderer,
  webrtcIssueRenderer,
} from "./renderers"
import StatePane from "./StatePane"
import TimelineWrapper from "./TimelineWrapper"
import { Pane, SplitPane, TabPanel, useTabs } from "./ui"
import UserFeedback from "./UserFeedback"
import VideoOverview from "./VideoOverview"

type ClientStateIndexPageProps = {
  session?: BrowserVideoClientStateSession
}

const ClientStateToolPage: FC<ClientStateIndexPageProps> = ({ session }) => {
  const { dispatchSessionChanged: setCurrentSession } = useAVClientStateViewerDispatchers()

  useEffect(() => {
    setCurrentSession(session)
  }, [session])

  const currentSession = useCurrentSession()
  const selfParticipant = useMemo(() => currentSession?.userAccountId, [currentSession])

  const { currentTab, handleChange } = useTabs("sfu")
  const { currentTab: currentTabBottom, handleChange: handleChangeBottom } =
    useTabs("combinedEvents")

  const { currentTab: currentViewerTab, handleChange: handleChangeViewerTab } = useTabs("dashboard")

  return (
    <div className="dark">
      <CSTLayout
        left={<Participants selfParticipant={selfParticipant} />}
        center={
          <Pane sx={{ overflow: "auto" }}>
            <TabContext value={currentViewerTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList value={currentViewerTab} onChange={handleChangeViewerTab}>
                  <Tab label="Overview" value="videoOverview" />
                  <Tab label="Dashboard (beta)" value="dashboard" />
                </TabList>
              </Box>
              <Box sx={{ height: "100%", position: "relative" }}>
                <TabPanel value="videoOverview">
                  <Pane>
                    <VideoOverview />
                  </Pane>
                </TabPanel>
                <TabPanel value="dashboard">
                  <Pane>
                    <Dashboard />
                  </Pane>
                </TabPanel>
              </Box>
            </TabContext>
          </Pane>
        }
        mid={
          <SplitPane>
            <Pane>
              <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList value={currentTab} onChange={handleChange}>
                    <Tab label="SFU" value="sfu" />
                    <Tab label="Logs" value="logs" />
                    <Tab label="Actions" value="actions" />
                    <Tab label="WebRTC/Issues" value="webrtc_issues" />
                    <Tab label="Devices" value="devices" />
                    <Tab label="Network Test Results" value="network_test_results" />
                  </TabList>
                </Box>
                <Box sx={{ height: "100%", position: "relative" }}>
                  <TabPanel value="sfu">
                    <Events renderers={sfuRenderer} />
                  </TabPanel>
                  <TabPanel value="logs">
                    <LogEvents />
                  </TabPanel>
                  <TabPanel value="actions">
                    <Events renderers={actionRenderer} />
                  </TabPanel>
                  <TabPanel value="webrtc_issues">
                    <Events renderers={webrtcIssueRenderer} />
                  </TabPanel>
                  <TabPanel value="devices">
                    <DevicesTab />
                  </TabPanel>
                  <TabPanel value="network_test_results">
                    <Events renderers={networkTestResultsRenderer} />
                  </TabPanel>
                </Box>
              </TabContext>
            </Pane>
            <StatePane />
          </SplitPane>
        }
        bottom={
          <Pane>
            <TabContext value={currentTabBottom}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList value={currentTabBottom} onChange={handleChangeBottom}>
                  <Tab label="Combined Events" value="combinedEvents" />
                  <Tab label="User Feedback" value="userFeedback" />
                  <Tab label="Raw Events" value="rawCombinedEvents" />
                </TabList>
              </Box>
              <Box sx={{ height: "100%", position: "relative" }}>
                <TabPanel value="combinedEvents">
                  <Events renderers={combinedRenderers} />
                </TabPanel>
                <TabPanel value="userFeedback">
                  <UserFeedback />
                </TabPanel>
                <TabPanel value="rawCombinedEvents">
                  <Events renderers={rawRenderer} />
                </TabPanel>
              </Box>
            </TabContext>
          </Pane>
        }
        footer={
          <>
            <TimelineWrapper />
          </>
        }
      />
    </div>
  )
}

export default ClientStateToolPage
